import HeaderCom from '../../../components/M-header.vue';

export default {
  name: 'adminPay',
  components: {
    HeaderCom
  },
  data() {
    return {
      total: 0, // 总条目数
      currentPage: 1, // 当前页
      limit: 10, // 每页显示数量
      loading: false,
      payArray: [],
      formInline: {},
      payList: [{ value: '0', label: '未缴费' }, { value: '1', label: '已缴费' }],
      degreeList: JSON.parse(sessionStorage.getItem('degreeList')),
      specialList: JSON.parse(sessionStorage.getItem('specialList')),
      levelList: JSON.parse(sessionStorage.getItem('levelList')),
      jobList: JSON.parse(sessionStorage.getItem('jobList')),
      // stateList: [{ value: '1', label: '未提交' }, { value: '2', label: '待审核' }, { value: '3', label: '审核通过' }, { value: '4', label: '审核不通过' }],
      tabColumns: [
        { title: '序号', type: 'index', width: 50, align: 'center' },
        { title: '平台订单号', key: 'orderNo', align: 'center', tooltip: true, resizable: true, width: 120 },
        { title: '支付宝订单号', key: 'transactionSn', align: 'center', tooltip: true, resizable: true, width: 120 },
        { title: '缴费时间', key: 'payTime', align: 'center', tooltip: true, resizable: true, width: 110 },
        {
          title: '缴费状态', key: 'regPay', align: 'center', width: 100,
          render: (h, params) => {
            let tmpStr = "";
            if (params.row.regPay == 1) {
              tmpStr = "已缴费";
            } else {
              tmpStr = "未缴费";
            }
            return h('span', {
              style: {
                color: (params.row.regPay == 1) ? "#19be6b" : "#ed3f14"
              }
            }, tmpStr)
          }
        },
        { title: '姓名', key: 'regName', align: 'center', width: 100 },
        { title: '性别', key: 'gender', align: 'center', width: 60 },
        { title: '身份证号码', key: 'idNumber', align: 'center', tooltip: true, resizable: true, width: 120 },
        { title: '联系电话', key: 'tel', align: 'center', width: 110 },
        { title: '最高学历', key: 'highestDegree', align: 'center', width: 100 },
        { title: '测试专业名称', key: 'examMajor', align: 'center', tooltip: true, resizable: true, minWidth: 120 },
        { title: '测试级别', key: 'examMajorTitle', align: 'center', width: 100 },
        { title: '工作单位', key: 'unitName', align: 'center', tooltip: true, resizable: true, width: 120 },
        { title: '现任专业职务', key: 'majorJob', align: 'center', tooltip: true, width: 120 },
        { title: '现任专业职务级别', key: 'majorJobTitle', align: 'center', tooltip: true, width: 140 },
        {
          title: '审核状态', key: 'workflowStatus', width: 120, align: 'center',
          render: (h, params) => {
            let tmpStr = "审核通过";
            return h('span', {
              style: {
                color: "#19be6b"
              }
            }, tmpStr)
          }
        }
      ],
      tabData: [],
      moneyModal: false, // 设置缴费金额弹框
      moneyColumn: [
        { title: '序号', type: 'index', width: 50, align: 'center' },
        { title: '测试专业名称', key: 'examSpeciality', align: 'center', width: 400 },
        { title: '测试级别', key: 'examSpecialityLevel', align: 'center' },
        { title: '缴费金额(元)', slot: 'amount', align: 'center' },
        { title: '操作', slot: 'action', width: 150, align: 'center' }
      ],
      moneyData: [],
      editIndex: -1,  // 当前聚焦的输入框的行数
      editMoney: '', // 缴费金额输入框
      payTime: {
        shortcuts: [
          {
            text: '最近一周',
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            }
          },
          {
            text: '最近一个月',
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [start, end];
            }
          },
          {
            text: '最近三个月',
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              return [start, end];
            }
          }
        ]
      },
      batchList: [],//批次
    }
  },
  mounted() {
    this.getBatchOptions();
  },
  methods: {
    //批次list  同步方法必须先执行他
     getBatchOptions() {
       this.$manageHttp.getBatchOptions().then(data => {
        if (data.code == '200') {
          this.batchList = data.data;
          this.formInline.batchId=this.batchList[0].id;
          this.getData();
        } else {
          this.$Message.error(data.msg);
        }
      }).catch(error => {
        this.$Message.error(error);
      })
    },

    onSearch() {
      this.currentPage = 1;
      // this.$refs.page.init();
      this.getData();
    },
    // 页码改变的回调，返回改变后的页码(当前页)
    sizeChange(val) {
      this.currentPage = val;
      this.getData();
    },
    // 切换每页条数时的回调，返回切换后的每页条数(每页数)
    pageChange(val) {
      this.limit = val;
      this.getData();
    },
    auditChange(val) {
      this.payArray[0] = val[0];
      this.payArray[1] = val[1];
    },
    getData() {
      this.loading = true;
      var params = {
        pageNum: this.currentPage,
        pageSize: this.limit,
        regPay: this.formInline.regPay,
        beginPayTime: this.payArray[0],
        endPayTime: this.payArray[1],
        name: this.formInline.regName,
        idNumber: this.formInline.idNumber,
        tel: this.formInline.tel,
        unitName: this.formInline.unitName,
        highestDegree: this.formInline.highestDegree,
        examMajor: this.formInline.examMajor,
        examMajorTitle: this.formInline.examMajorTitle,
        majorJob: this.formInline.majorJob,
        majorJobTitle: this.formInline.majorJobTitle,
        batchId:this.formInline.batchId
      }
      this.$manageHttp.getRegistrationFeeList(params).then(data => {
        if (data.code == '200') {
          this.loading = false;
          this.tabData = data.rows;
          this.total = data.total;
        } else {
          this.$Message.error(data.msg);
        }
      }).catch(error => {

      })
    },
    // 设置缴费金额
    setPayChange(flag) {
      if (flag == true) {
        this.$manageHttp.payList().then(data => {
          if (data.code == '200') {
            this.moneyData = data.rows;
          } else {
            this.$Message.error(data.msg);
          }
        }).catch(error => {
          this.$Message.error(error);
        })
      }
    },
    // 设置缴费金额---修改
    handleEdit(row, index) {
      console.log(row, 'row');
      this.editMoney = row.amount;
      this.editIndex = index;
    },
    // 设置缴费金额---保存
    handleSave(row, index) {
      var params = {
        id: row.id,
        amount: this.editMoney
      }
      this.$manageHttp.updatePay(params).then(data => {
        if (data.code == '200') {
          this.moneyData[index].amount = this.editMoney;
          this.editIndex = -1;
          this.$Message.success(data.msg);
        } else {
          this.$Message.error(data.msg);
        }
      }).catch(error => {
        this.$Message.error(error);
      })
    },
    // 导出筛选名单
    exportList() {
      var params = {
        pageNum: 1,
        pageSize: 10000,
        regPay: this.formInline.regPay,
        beginPayTime: this.payArray[0],
        endPayTime: this.payArray[1],
        name: this.formInline.regName,
        idNumber: this.formInline.idNumber,
        tel: this.formInline.tel,
        unitName: this.formInline.unitName,
        highestDegree: this.formInline.highestDegree,
        examMajor: this.formInline.examMajor,
        examMajorTitle: this.formInline.examMajorTitle,
        majorJob: this.formInline.majorJob,
        majorJobTitle: this.formInline.majorJobTitle,
        batchId:this.formInline.batchId
      };
      this.$manageHttp.exportRegistrationFeeList(params).then(data => {
        if (data.code == '200') {
          this.$manageHttp.download(data.msg);
        } else {
          this.$Message.error(data.msg);
        }
      }).catch(error => {
        this.$Message.error(error);
      })
    }
  }
}